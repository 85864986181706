<template>
  <div>
    <v-btn
      color="primary"
      rounded
      @click="open_bank_dialog(null)"
      absolute
      fab
      top
      right
    >
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
    <h1 v-if="this.user_bank_accounts.length == 0" class="text-center mt-6">
      {{ $t("no_bank_accs") }}
    </h1>
    <v-list class="my-4">
      <v-row justify="center">
        <v-col
          class="text-center"
          v-for="(acc, i) in this.user_bank_accounts"
          :key="i"
          cols="12"
          md="3"
        >
          <h4>{{ acc.Bank }}</h4>
          <p v-if="!acc.IsForeign" class="agency-acc">
            {{ $t("agency") }} {{ acc.Agency }}
          </p>
          <p class="agency-acc">{{ $t("account") }} {{ acc.Account }}</p>
          <p v-if="acc.IsForeign" class="agency-acc">
            {{ $t("beneficiary") }} {{ acc.Beneficiary }}
          </p>
          <v-btn
            color="primary"
            class="my-1 mx-auto"
            height="2px"
            @click="open_bank_dialog(acc)"
          >
            {{ $t("edit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-list>
    <v-dialog v-if="dialog" v-model="dialog" width="40%">
      <AddBankAccountModal
        :BankAcc="bank_acc"
        @close="dialog = false"
        @add="add_account"
        @edit="edit_account"
      ></AddBankAccountModal>
    </v-dialog>
  </div>
</template>
<style scoped>
.agency-acc {
  color: grey;
  font-size: 13px;
  margin-bottom: 0px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import AddBankAccountModal from "@/views/pages/profile/form/AddBankAccountModal";
export default {
  name: "ManageBankAccounts",
  components: {
    AddBankAccountModal,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    user_bank_accounts: [],
    bank_acc: {
      Id: null,
      Bank: null,
      Agency: null,
      Account: null,
    },
    dialog: false,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    min_valid() {
      return (v) => v.length >= 8 || this.$t("min_char");
    },
    special_valid() {
      return (v) =>
        !/[*|\":<>[\]{}`\\()';&$]/.test(v) || this.$t("special_char");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
  },
  props: {
    User: Object,
  },

  async created() {
    this.user_bank_accounts = this.User.BankAccounts.map((acc, index) => {
      return {
        Id: acc.Id,
        BankAccountId: acc.BankAccount.Id,
        Bank: acc.BankAccount.Bank,
        Agency: acc.BankAccount.Agency,
        Account: acc.BankAccount.Account,
        UserId: acc.UserId,
        IsForeign: false,
      };
    });

    this.User.InterBankAccounts.map((acc, index) =>
      this.user_bank_accounts.push({
        Id: acc.Id,
        BankAccountId: acc.InterBankAccount.Id,
        Bank: acc.InterBankAccount.Bank,
        Account: acc.InterBankAccount.Account,
        Address: acc.InterBankAccount.Address,
        OtherInformation: acc.InterBankAccount.OtherInformation,
        Beneficiary: acc.InterBankAccount.Recipient,
        ABA: acc.InterBankAccount.RoutingNumber,
        SWIFT: acc.InterBankAccount.Swift,
        UserId: acc.UserId,
        IsForeign: true,
      })
    );
  },

  methods: {
    open_bank_dialog(acc) {
      if (acc != null) {
        this.bank_acc = acc;
      } else {
        this.bank_acc = {
          UserId: this.User.Id,
          Bank: null,
          Agency: null,
          Account: null,
        };
      }
      this.dialog = true;
    },
    add_account(account) {
      this.user_bank_accounts.push(account);
      this.dialog = false;
    },
    edit_account(account) {
      var index = this.user_bank_accounts.indexOf(this.bank_acc);
      this.user_bank_accounts[index] = account;
      this.dialog = false;
    },
  },
};
</script>
