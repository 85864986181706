<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'" color="white">
    <v-card-title>
      <h3 class="dxa_modal_title h3">
        {{ $t("new_bank_acc") }}
      </h3>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-container>
        <v-form @submit.stop.prevent="add_bank_account()" ref="form">
          <ManageBankAccountComponent :BankAccount="bank_acc" />
          <v-card-actions class="pl-0 mx-10">
            <v-spacer></v-spacer>
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary white-color"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError white-color"
              color="red"
              min-width="100"
              @click="$emit('close')"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import ManageBankAccountComponent from "@/components/dxa/BankAccount/ManageBankAccount";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "AddBankAccountModal",
  components: {
    ManageBankAccountComponent,
  },
  props: {
    BankAcc: Object,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    bank_acc: null,
    edit: false,
    loading: false,
  }),
  async created() {
    if (this.BankAcc.BankAccountId != null) {
      this.edit = true;
      this.bank_acc = {
        Id: this.BankAcc.Id,
        BankAccountId: this.BankAcc.BankAccountId,
        UserId: this.BankAcc.UserId,
        Bank: this.BankAcc.Bank,
        Agency: this.BankAcc.Agency,
        Account: this.BankAcc.Account,
        Beneficiary: this.BankAcc.Beneficiary,
        Address: this.BankAcc.Address,
        OtherInformation: this.BankAcc.OtherInformation,
        SWIFT: this.BankAcc.SWIFT,
        ABA: this.BankAcc.ABA,
        IsForeign: this.BankAcc.IsForeign,
      };
    } else {
      this.edit = false;
      this.bank_acc = {
        UserId: this.BankAcc.UserId,
        Bank: null,
        Agency: null,
        Account: null,
        Beneficiary: null,
        Address: null,
        OtherInformation: null,
        SWIFT: null,
        ABA: null,
        IsForeign: false,
      };
    }
  },
  computed: {},
  methods: {
    add_bank_account() {
      this.loading = true;
      let urlEdit = "investor/bank-account/national";
      let data = {
        Id: this.bank_acc.Id,
        UserId: this.bank_acc.UserId,
        BankAccount: {
          Id: this.bank_acc.BankAccountId,
          Bank: this.bank_acc.Bank,
          Agency: this.bank_acc.Agency,
          Account: this.bank_acc.Account,
        },
      };
      if (this.bank_acc.IsForeign) {
        urlEdit = "investor/bank-account/international";
        data = {
          Id: this.bank_acc.Id,
          UserId: this.bank_acc.UserId,
          InterBankAccount: {
            Id: this.bank_acc.BankAccountId,
            Bank: this.bank_acc.Bank,
            Account: this.bank_acc.Account,
            Recipient: this.bank_acc.Beneficiary,
            Address: this.bank_acc.Address,
            Swift: this.bank_acc.SWIFT,
            RoutingNumber: this.bank_acc.ABA,
            OtherInformation: this.bank_acc.OtherInformation,
          },
        };
      }
      this.apiService
        .postRequest(urlEdit, data)
        .then((resp) => {
          let new_bank_acc = {
            Id: resp.content.id,
            BankAccountId:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.id
                : resp.content.bankAccount.id,
            Bank:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.bank
                : resp.content.bankAccount.bank,
            Account:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.account
                : resp.content.bankAccount.account,
            Agency:
              resp.content.bankAccount != undefined
                ? resp.content.bankAccount.agency
                : null,
            Address:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.address
                : null,
            OtherInformation:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.otherInformation
                : null,
            Beneficiary:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.recipient
                : null,
            ABA:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.routingNumber
                : null,
            SWIFT:
              resp.content.interBankAccount != undefined
                ? resp.content.interBankAccount.swift
                : null,
            UserId:
              resp.content.interBankAccount != undefined
                ? resp.content.userId
                : null,
            IsForeign: resp.content.interBankAccount != undefined,
            UserId: resp.content.userId,
          };
          this.$emit(this.edit ? "edit" : "add", new_bank_acc);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
