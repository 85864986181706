import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:_vm.gs.isMobile() ? '' : 'dxa_modal',attrs:{"color":"white"}},[_c(VCardTitle,[_c('h3',{staticClass:"dxa_modal_title h3"},[_vm._v(" "+_vm._s(_vm.$t("new_bank_acc"))+" ")])]),_c(VCardText,{staticClass:"pt-0"},[_c(VContainer,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.add_bank_account()}}},[_c('ManageBankAccountComponent',{attrs:{"BankAccount":_vm.bank_acc}}),_c(VCardActions,{staticClass:"pl-0 mx-10"},[_c(VSpacer),_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary white-color","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError white-color",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }