<template>
  <div>
    <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
        <v-checkbox
          class="field"
          color="primary"
          outlined
          dense
          v-model="BankAccount.IsForeign"
          @click="$emit('changeForegin')"
          data-test="complete_profile:bank_foreign"
        >
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("bank_is_foreign") }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row
      v-if="!BankAccount.IsForeign"
      :class="isMobile() ? 'mx-0' : 'mx-10'"
      style="text-align: left"
    >
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
        <p class="ml-2 mb-2 dark-color">
          {{ $t("bank") }}
        </p>
        <v-autocomplete
          class="field my-input-style"
          :placeholder="$t('insert_bank_code')"
          color="primary"
          :no-data-text="$t('not_found')"
          outlined
          dense
          :items="banks"
          :filter="customFilter"
          item-value="COMPE"
          :rules="[required]"
          v-model="BankAccount.Bank"
          data-test="complete_profile:bank"
        >
          <template slot="selection" slot-scope="data">
            <span>{{ `${data.item.COMPE} - ${data.item.LongName}` }}</span>
          </template>
          <template slot="item" slot-scope="data">
            <span>{{ `${data.item.COMPE} - ${data.item.LongName}` }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
        <p class="ml-2 mb-2 dark-color">
          {{ $t("agency") }}
        </p>
        <v-text-field
          class="field my-input-style"
          :placeholder="$t('insert_bank_agency')"
          color="primary"
          outlined
          :onkeyup="checkTheMaskType()"
          v-mask="agency_mask"
          dense
          :rules="[
            (v) => (!!v && v.length >= 3 && v.length <= 6) || required(),
          ]"
          v-model="BankAccount.Agency"
          data-test="complete_profile:bank_agency"
        />
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
        <p class="ml-2 mb-2 dark-color">
          {{ $t("account") }}
        </p>
        <v-text-field
          class="field my-input-style"
          :placeholder="$t('insert_bank_account')"
          color="primary"
          v-mask="account_mask"
          :onkeyup="checkTheMaskType()"
          outlined
          dense
          :rules="[
            (v) => (!!v && v.length >= 4 && v.length <= 14) || required(),
          ]"
          v-model="BankAccount.Account"
          data-test="complete_profile:bank_account"
        />
      </v-col>
    </v-row>

    <v-row
      v-else
      :class="isMobile() ? 'mx-0' : 'mx-10'"
      style="text-align: left"
    >
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
        <p class="ml-2 mb-2 my-input-style">
          {{ $t("bank") }}
        </p>
        <v-text-field
          class="field"
          color="primary"
          outlined
          dense
          :rules="[required]"
          v-model="BankAccount.Bank"
          data-test="complete_profile:bank_account"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
        <p class="ml-2 mb-2 my-input-style">
          {{ $t("account") }}
        </p>
        <v-text-field
          class="field"
          color="primary"
          outlined
          dense
          :rules="[required]"
          v-model="BankAccount.Account"
          data-test="complete_profile:bank_account"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
        <p class="ml-2 mb-2 my-input-style">
          {{ $t("beneficiary") }}
        </p>
        <v-text-field
          class="field"
          color="primary"
          outlined
          dense
          :rules="[required]"
          v-model="BankAccount.Beneficiary"
          data-test="complete_profile:beneficiary"
        />
      </v-col>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
        <p class="ml-2 mb-2 my-input-style">
          {{ $t("address") }}
        </p>
        <v-text-field
          class="field"
          color="primary"
          outlined
          dense
          :rules="[required]"
          v-model="BankAccount.Address"
          data-test="complete_profile:bank_address"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
        <div class="swift-tooltip-box">
          <p class="ml-2 mb-0">
            {{ $t("swift") }}
          </p>
          <v-tooltip
            v-model="swiftTooltip"
            max-width="300"
            left
            v-if="gs.isMobile()"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <font-awesome-icon
                  @click="swiftTooltip = !swiftTooltip"
                  icon="fa-solid fa-circle-info"
                  class="swift-tooltip-icon"
                />
              </div>
            </template>
            <span>{{ $t("swift_tooltip_content") }}</span>
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <font-awesome-icon
                  icon="fa-solid fa-circle-info"
                  class="swift-tooltip-icon"
                />
              </div>
            </template>
            <span>{{ $t("swift_tooltip_content") }}</span>
          </v-tooltip>
        </div>
        <v-text-field
          class="field my-input-style"
          color="primary"
          outlined
          dense
          :rules="[required]"
          v-model="BankAccount.SWIFT"
          data-test="complete_profile:bank_swift"
        />
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
        <div class="aba-tooltip-box">
          <p class="ml-2 mb-0">
            {{ $t("aba") }}
          </p>
          <v-tooltip
            max-width="300"
            left
            v-if="gs.isMobile()"
            v-model="abaTooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <font-awesome-icon
                  @click="abaTooltip = !abaTooltip"
                  icon="fa-solid fa-circle-info"
                  class="aba-tooltip-icon"
                />
              </div>
            </template>
            <span>{{ $t("aba_tooltip_content") }}</span>
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <font-awesome-icon
                  icon="fa-solid fa-circle-info"
                  class="aba-tooltip-icon"
                />
              </div>
            </template>
            <span>{{ $t("aba_tooltip_content") }}</span>
          </v-tooltip>
        </div>
        <v-text-field
          class="field"
          color="primary"
          outlined
          dense
          :rules="[required]"
          v-model="BankAccount.ABA"
          data-test="complete_profile:bank_aba"
        />
      </v-col>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
        <p class="ml-2 mb-2">
          {{ $t("other_informations") }}
        </p>
        <v-textarea
          class="field my-input-style"
          color="primary"
          outlined
          dense
          v-model="BankAccount.OtherInformation"
          data-test="complete_profile:bank_other_informations"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.field {
  box-sizing: border-box;
  border-radius: 10px;
}

.swift-tooltip-box,
.aba-tooltip-box {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}

.swift-tooltip-icon,
.aba-tooltip-icon {
  width: 14px;
  height: 14px;
}

.my-input-style >>> .v-select__slot input,
.my-input-style >>> .v-select__slot span,
.my-input-style >>> .v-text-field__slot input {
  color: var(--dark) !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import banksData from "@/shared/data/banks.json";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "ManageBankAccountComponent",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    valid: true,
    lazy: false,
    banks: banksData,
    alert: null,
    account_mask: null,
    agency_mask: null,
    abaTooltip: false,
    swiftTooltip: false,
  }),
  async created() {},
  props: {
    BankAccount: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    customFilter(item, queryText, itemText) {
      const textOne = item.COMPE.toLowerCase();
      const textTwo = item.LongName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    checkTheMaskType() {
      if (this.BankAccount) {
        if (this.BankAccount.Agency != null) {
          if (this.BankAccount.Agency.length <= 5) {
            this.agency_mask = "######";
          }
        }
        if (this.BankAccount.Account != null) {
          if (this.BankAccount.Account.length <= 5) {
            this.account_mask = "###-#";
          } else if (this.BankAccount.Account.length == 6) {
            this.account_mask = "####-#";
          } else if (this.BankAccount.Account.length == 7) {
            this.account_mask = "#####-#";
          } else if (this.BankAccount.Account.length == 8) {
            this.account_mask = "######-#";
          } else if (this.BankAccount.Account.length == 9) {
            this.account_mask = "#######-#";
          } else if (this.BankAccount.Account.length == 10) {
            this.account_mask = "########-#";
          } else if (this.BankAccount.Account.length == 11) {
            this.account_mask = "#########-#";
          } else if (this.BankAccount.Account.length == 12) {
            this.account_mask = "##########-#";
          } else if (this.BankAccount.Account.length == 13) {
            this.account_mask = "###########-#";
          } else if (this.BankAccount.Account.length == 14) {
            this.account_mask = "############-#";
          }
        }
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
  },
};
</script>
